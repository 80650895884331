import commonConfig, { getLpHost } from './common.config';

export default function productionConfig() {
  const apiHost = 'https://api.leadpages.io';
  const lpHost = getLpHost({
    appspotHost: 'https://lead-pages.appspot.com',
    allowedDomains: ['leadpages.com', 'leadpages.net'],
    defaultHost: 'my.leadpages.net',
  });
  return {
    ...commonConfig(apiHost, lpHost),
    ADSENSE_CLIENT_ID: '233365171690-253t2csqdja9kal36iaod3ad1pehoaao.apps.googleusercontent.com',
    CHEVRON: {
      ENV: 'PRODUCTION',
      storageUrl: `${lpHost}/sso`,
    },
    DEBUG: false,
    HUBSPOT_PORTAL_ID: '21794907',
    ENVIRONMENT: 'production',
    FIREBASE_SERVICE_URL: 'https://lpb3.firebaseIO.com/',
    FULLSTORY_ORG_ID: 'P8TF',
    LEADBOX_EMBED_SCRIPT_URL: 'https://embed.lpcontent.net/leadboxes/current/embed.js',
    MIXPANEL_TOKEN: 'f8a957e8bccc8753e8ba8953819c332e',
    OBSERVATIONS_ENABLED: true,
    OPTIN_BACKUP_HOST: 'https://optin-snf-dot-lead-pages.appspot.com',
    SENTRY_DSN: 'https://c47513774ab14cf4b634f4d68ea58971@sentry.io/1442296',
    USER_PUBLISH_DOMAIN: 'lpages.co',
    ZOPIM_KEY: '2CrzDCa9JqXmBcJY1G1AACvBEVK3Es1k',
    FORETHOUGHT_KEY: '0a8dac53-7465-4802-9d24-8d8efd41a076',
    VELT_API_KEY: 'CyLbGsUECeScbPjFN4DN',
  };
}
