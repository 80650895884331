import commonConfig from './common.config';

export default function dockerConfig() {
  const apiHost = 'https://stargate.docker';
  const lpHost = 'https://leadpages.docker';
  return {
    ...commonConfig(apiHost, lpHost),
    ADSENSE_CLIENT_ID: '510274384657-a7lfnn95c8lv48t4aflem3ktggbjbone.apps.googleusercontent.com',
    CHEVRON: {
      ENV: 'DOCKER',
      storageUrl: `${lpHost}/sso`,
    },
    DEBUG: true,
    HUBSPOT_PORTAL_ID: '24139017',
    ENVIRONMENT: 'docker',
    FIREBASE_SERVICE_URL: 'https://lp-playground.firebaseio.com/',
    FULLSTORY_ORG_ID: null,
    LEADBOX_EMBED_SCRIPT_URL: 'https://embed.lpcontenttest.net/leadboxes/current/embed.test.js',
    MIXPANEL_TOKEN: '4c775046b3a38a12eb9fab677bdced41',
    OBSERVATIONS_ENABLED: false,
    OPTIN_BACKUP_HOST: 'https://optin-snf-dot-leadpage-test.appspot.com',
    SENTRY_DSN: null,
    USER_PUBLISH_DOMAIN: 'lpages.docker',
    ZOPIM_KEY: null,
    FORETHOUGHT_KEY: null,
    VELT_API_KEY: '01ayHyIIK5qgnTufKala',
  };
}
